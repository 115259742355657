import { render, staticRenderFns } from "./ArticleItem.vue?vue&type=template&id=6951c2dd&scoped=true&"
import script from "./ArticleItem.vue?vue&type=script&lang=js&"
export * from "./ArticleItem.vue?vue&type=script&lang=js&"
import style0 from "./ArticleItem.vue?vue&type=style&index=0&id=6951c2dd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6951c2dd",
  null
  
)

export default component.exports
<template>
  <div class="home">
    <div class="header">
      <div class="title">{{ title }}</div>
      <div v-if="article" class="continue" @click="openArticle">
        <div class="content">
          <span>{{ article.label }}</span>
          <div class="title">{{ article.title }}</div>
        </div>
        <div class="icon-wrapper">
          <IconBooksHeader class="icon" />
        </div>
      </div>
    </div>

    <div class="content">
      <LastTests />
      <AdditionMaterial />
      <Statistic />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { getLocal } from "@/helpers";

import LastTests from "@/components/home/LastTests";
import AdditionMaterial from "@/components/home/AdditionMaterial";
import Statistic from "@/components/home/Statistic";
import IconBooksHeader from "@/assets/images/svg/two-books.svg?inline";

export default {
  name: "Home",
  components: {
    IconBooksHeader,
    LastTests,
    Statistic,
    AdditionMaterial
  },
  data() {
    return {
      article: null
    }
  },
  computed: {
    ...mapState(["title", "articles"]),
  },
  methods: {
    ...mapMutations(['setArticle', 'setArticleSection', 'setSection']),
    getCurrentArticle() {
      let hash = getLocal("last");
      let section = null;
      let listSection = null;
      let article = {};

      if (hash) {
        hash = hash.split('|section|');

        if (hash.length > 1) {
          section = hash[0]
          hash = hash[1]
        } else {
          hash = hash[0]
        }

        if (section) {
          listSection = this.articles.find((item) => 
            item.section && item.title === section
          )

          article = listSection.list.find((item) => item.hash === hash)
        } else {
          article = this.articles.find((item) => item.hash === hash);
        }
      } else {
        article = this.articles[0];
      }

      this.article = {
        ...article,
        listSection,
        label: hash ? "Продолжить" : "Приступить к курсу",
      }
    },
    openArticle() {
      if (this.article.listSection) {
        this.setSection(this.article.listSection)
        this.setArticleSection(this.article)
      } else {
        this.setArticle(this.article)
      }
    }
  },
  mounted() {
    this.getCurrentArticle()
  }
};
</script>

<style lang="scss" scoped>
.home {
  .header {
    background: var(--main);
    border-radius: 0 0 25px 25px;
    margin-bottom: 20px;
    padding: 15px 15px 25px;

    & > .title {
      color: var(--white);
      font-weight: bold;
      font-size: 32px;
      margin-bottom: 30px;
    }

    .continue {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: rgba(255, 255, 255, 0.16);
      border-radius: 6px;
      padding: 15px 10px;

      .content {
        font-size: 14px;
        color: var(--white);

        span {
          opacity: 0.6;
        }

        .title {
          margin-top: 7px;
        }
      }

      .icon-wrapper {
        position: relative;
        width: 70px;

        .icon {
          position: absolute;
          top: -55px;
          left: -11px;
          width: 79px;
          height: 79px;
        }
      }
    }
  }

  & > .content {
    padding: 0 15px 20px;

    .lats-tests {
      margin-bottom: 25px;
    }
  }
}
</style>

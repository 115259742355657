<template>
  <div class="test-item" @click="setTest(test)">
    <div class="left">
      <component
        v-if="test.finish"
        :is="test.status ? 'IconOk' : 'IconNo'"
        class="icon"
        :class="[test.status ? 'ok' : 'no']"
      />
      <div class="title">{{ test.title }}</div>
    </div>
    <div class="question">
      <template v-if="!test.finish"> {{ test.length }} вопросов </template>
      <template v-else> {{ test.decided }} / {{ test.length }}</template>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import IconOk from "@/assets/images/svg/check-ok.svg?inline";
import IconNo from "@/assets/images/svg/check-no.svg?inline";

export default {
  name: "TestItem",
  components: {
    IconOk,
    IconNo,
  },
  props: {
    test: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    ...mapMutations(["setTest"]),
  },
};
</script>

<style lang="scss" scoped>
.test-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 13px;
  background: #8ab2fc0d;
  border-radius: 6px;
  color: var(--text-black);

  .left {
    display: flex;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;

      &.ok {
        fill: var(--green);
      }

      &.no {
        fill: var(--error);
      }
    }

    .title {
      margin: 0 10px;
    }
  }

  .question {
    color: var(--text-silver);
    white-space: nowrap;
  }
}
</style>

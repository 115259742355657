<template>
  <div class="navbar">
    <div
      v-for="(item, index) in listNav"
      :key="index"
      class="item"
      :class="{ active: page.id === item.id }"
      @click="setPage(item)"
    >
      <component :is="`icon-${item.component}`" class="icon" />
      <div class="title">{{ item.title }}</div>
    </div>

    <div class="btn" @click="setIsMoreCourses(true)">Больше курсов</div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import IconHome from "@/assets/images/svg/home.svg?inline";
import IconArticles from "@/assets/images/svg/books.svg?inline";
import IconTests from "@/assets/images/svg/tests.svg?inline";

export default {
  name: "Navbar",
  components: {
    IconHome,
    IconArticles,
    IconTests,
  },
  data() {
    return {
      listNav: [
        {
          id: 1,
          title: "Главная",
          component: "home",
        },
        {
          id: 2,
          title: "Курс",
          component: "articles",
        },
        {
          id: 3,
          title: "Тесты",
          component: "tests",
        },
      ],
    };
  },
  computed: {
    ...mapState(["page"]),
  },
  methods: {
    ...mapMutations(["setPage", "setIsMoreCourses"]),
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--navbar-height);
  padding: 0 15px;
  border-top: 1px solid var(--support-silver);

  .item {
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--support-silver-menu);
    transition: all 0.2s;

    .title {
      font-size: 13px;
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-bottom: 5px;
      fill: var(--support-silver-menu);
    }

    &.active {
      color: var(--main);

      .icon {
        fill: var(--main);
      }
    }
  }

  .btn {
    width: 40%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--main);
    border-radius: 50px;
    font-size: 15px;
    color: var(--main);
  }
}
</style>
